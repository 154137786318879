import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { IntlProvider } from "react-intl"

import Seo from "../seo"

if (typeof window !== "undefined") {
  window.addEventListener("beforeinstallprompt", function(e) {
    e.preventDefault()
    return false
  })
}

export default ({ children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            lang
            seo {
              title
              description
              keywords
              author
            }
          }
        }
      }
    `
  )

  return (
    <IntlProvider locale="nl-nl">
      <Helmet htmlAttributes={{ lang: data.site.siteMetadata.lang }}>
        <meta charSet="utf-8" />

        {/* SVG favicon - Primary */}
        {/* <link rel="icon" type="image/svg+xml" href="/favicon.svg" /> */}
        
        {/* PNG fallbacks */}
        {/* <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" /> */}
        {/* <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" /> */}

        {/* Legacy favicon.ico fallback */}
        <link rel="icon" type="image/x-icon" href="/favicon.ico" sizes="16x16 32x32" />
        
        {/* Apple Touch Icon */}
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        
        {/* Web Manifest */}
        {/* <link rel="manifest" href="/manifest.webmanifest" /> */}

        <meta
          name="healthcheck"
          content="bed697a3-ad3e-420b-bbd8-961f936abe46"
        />
        {/* <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, viewport-fit=cover"
        /> */}
      </Helmet>

      <Seo
        title={data.site.siteMetadata.seo.title}
        description={data.site.siteMetadata.seo.description}
        keywords={data.site.siteMetadata.seo.keywords}
        author={data.site.siteMetadata.seo.author}
        canonical="/"
      />

      {children}
    </IntlProvider>
  )
}
